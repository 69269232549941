<template>
  <div class="wrap-layout">
    <div class="layout">
      <header class="header">
        <div class="header-content">
          <div class="brand"></div>
          <div class="live-time">{{ currentDate }} {{ liveTime }}</div>
          <div class="user">
            <dropdown>
              <template slot="toggler">
                {{ username }}
                <i class="fa fa-sort-down"></i>
              </template>
              <dropdown-content>
                <div class="item-dropdown my-1 px-3 py-2" @click="logout">Logout</div>
              </dropdown-content>
            </dropdown>
          </div>
        </div>
      </header>
      <main>
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>

<script>
import Dropdown from "../components/dropdown/Dropdown.vue";
import DropdownContent from "../components/dropdown/DropdownContent.vue";
import axios from "axios";
import moment from "moment";
export default {
  components: {
    Dropdown,
    DropdownContent,
  },
  data() {
    return {
      status: false,
      role: "",
      username: "",
      liveTime: null,
      currentDate: null,
    };
  },
  mounted() {
    this.role = sessionStorage.getItem("role");
    this.username = sessionStorage.getItem("username");
    let date = new Date();
    this.currentDate = moment(date).format("dddd, DD MMMM YYYY");
  },
  methods: {
    openToggle(e) {
      this.status = e;
    },
    logout() {
      sessionStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      this.$router.go("/login");
    },
  },
};
</script>
<style scoped>
.wrap-layout {
  min-height: 100vh;
}
.layout {
  height: 100%;
  display: flex;
  position: relative;
}

.header {
  height: 70px;
  padding: 5px 40px;
  background: #fff;
  color: #2c3e50;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  position: fixed;
  width: 100%;
  z-index: 9;
  line-height: 57px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user {
  cursor: pointer;
}

main {
  margin-top: 100px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .wrap-sidebar {
    margin-left: -240px;
  }
  .wrap-main-content {
    margin-left: 0;
  }
  .hide-side {
    margin-left: 0;
    width: 240px;
    z-index: 1;
  }
}
</style>
